import React from 'react';
import { Link } from 'gatsby';

import footer from '../img/footer.svg';
import facebook from '../img/social/facebook.svg';
import instagram from '../img/social/instagram.svg';
import twitter from '../img/social/twitter.svg';
import vimeo from '../img/social/vimeo.svg';

const Footer = ({ links }) => (
  <footer className="footer has-background-black has-text-white-ter">
    <div className="content has-text-centered">
      <img
        src={footer}
        alt="Shirin Pinto"
        style={{ height: '10em' }}
      />
    </div>
    <div className="content has-text-centered has-background-black has-text-white-ter">
      <div className="container has-background-black has-text-white-ter">
        <div style={{ maxWidth: '100vw' }} className="columns">
          <div className="column is-4">
            <section className="menu">
              <ul className="menu-list">
                <li>
                  <Link to="/" className="navbar-item">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="navbar-item" to="/philosophy">
                    Philosophie
                  </Link>
                </li>
                <li>
                  <Link className="navbar-item" to="/therapy">
                    Therapien
                  </Link>
                </li>
                <li>
                  <Link className="navbar-item" to="/products">
                    Produkte
                  </Link>
                </li>
                <li>
                  <a
                    className="navbar-item"
                    href="/admin/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Admin
                  </a>
                </li>
              </ul>
            </section>
          </div>
          <div className="column is-4">
            <section>
              <ul className="menu-list">
                <li>
                  <Link className="navbar-item" to="/about">
                    Über mich
                  </Link>
                </li>
                <li>
                  <Link className="navbar-item" to="/contact">
                    Kontakt
                  </Link>
                </li>
              </ul>
            </section>
          </div>
          <div className="column is-4 social">
            {links.facebook &&
              <a title="facebook" target="_blank" rel="noreferrer" href={links.facebook}>
                <img
                    src={facebook}
                    alt="Facebook"
                    style={{width: '1em', height: '1em'}}
                />
              </a>
            }
            {links.twitter &&
              <a title="twitter" target="_blank" rel="noreferrer" href={links.twitter}>
                <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{width: '1em', height: '1em'}}
                />
              </a>
            }
            {links.instagram &&
              <a title="instagram" target="_blank" rel="noreferrer" href={links.instagram}>
                <img
                    src={instagram}
                    alt="Instagram"
                    style={{width: '1em', height: '1em'}}
                />
              </a>
            }
            {links.vimeo &&
              <a title="vimeo" target="_blank" rel="noreferrer" href={links.vimeo}>
                <img
                    src={vimeo}
                    alt="Vimeo"
                    style={{width: '1em', height: '1em'}}
                />
              </a>
            }
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
